/*
 * Hi! The modules in this file are loaded on **every** page on github.com.
 * Please only add files here if the code does indeed need to be on every page.
 * If the code is needed on only some pages, please load it more sparingly by
 * following the docs at http://workers-playground-icy-pine-ac0b.fatiao.workers.dev/proxy/https://thehub.github.com/epd/engineering/dev-practicals/frontend/erb-pvc-catalyst/client-side-behaviors-with-catalyst/
 * To create a dynamically-loaded Catalyst component for your behavior instead.
 *
 */

import './github/behaviors/ajax-error'
import './github/behaviors/ajax-loading'
import './github/behaviors/ajax-pagination'
import './github/behaviors/analytics-events'
import './github/behaviors/animated-image'
import './github/behaviors/autocheck'
import './github/behaviors/autocomplete'
import './github/behaviors/autosearch-form'
import './github/behaviors/autoselect'
import './github/behaviors/autosubmit'
import './github/behaviors/avatar-reset-text'
import './github/behaviors/batch-deferred-content'
import './github/behaviors/billing-addons'
import './github/behaviors/blob-csv'
import './github/repositories/blob-header'
import './github/behaviors/branch-protection-integration-select'
import './github/behaviors/bulk-actions'
import './github/behaviors/bundle-download-stats'
import './github/behaviors/buttons'
import './github/behaviors/characters-remaining'
import './github/behaviors/check-all'
import './github/behaviors/clear-data-on-logout'
import './github/behaviors/clipboard-copy'
import './github/behaviors/code-nav'
import './github/behaviors/commenting/ajax'
import './github/behaviors/commenting/close'
import './github/behaviors/commenting/edit'
import './github/behaviors/commenting/focus'
import './github/behaviors/commenting/markdown'
import './github/behaviors/commenting/preview'
import './github/behaviors/commenting/reaction-suggestion'
import './github/behaviors/commits'
import './github/behaviors/confirm'
import './github/behaviors/company-name-input'
import './github/behaviors/company-owned'
import './github/behaviors/details'
import './github/behaviors/details-element'
import './github/behaviors/details-menu'
import './github/behaviors/dialog-helpers'
import './github/behaviors/disable-with'
import './github/behaviors/document-dropzone'
import './github/behaviors/encrypt'
import './github/behaviors/favicon'
import './github/behaviors/feature-preview-indicator'
import './github/behaviors/feature-preview-trigger'
import './github/behaviors/file-attachment'
import './github/behaviors/filter-input'
import './github/behaviors/filterable'
import './github/behaviors/fixed-offset-fragment-navigation-observer'
import './github/behaviors/flash'
import './github/behaviors/flash-observer'
import './github/behaviors/focus-delay'
import './github/behaviors/form-toggle'
import './github/behaviors/fuzzy-list'
import './github/behaviors/gfm'
import './github/behaviors/hash-change'
import './github/behaviors/header'
import './github/behaviors/hooks'
import './github/behaviors/hotkey-map'
import './github/behaviors/hovercards'
import './github/behaviors/html-validation'
import './github/behaviors/hydro-analytics-pageview'
import './github/behaviors/hydro-analytics-tracking'
import './github/behaviors/hydro-click-tracking'
import './github/behaviors/immediate-updates'
import './github/behaviors/include-fragment'
import './github/behaviors/indeterminate'
import './github/behaviors/keyboard-shortcuts'
import './github/behaviors/length-limited-input-with-warning'
import './github/behaviors/member-search-filter'
import './github/behaviors/notice'
import './github/behaviors/open-in-github-dev'
import './github/behaviors/permalink'
import './github/behaviors/permission-menu-form'
import './github/behaviors/turbo-timing'
import './github/behaviors/print-popup'
import './github/behaviors/poll-include-fragment'
import './github/behaviors/quick-submit'
import './github/behaviors/quote-selection'
import './github/behaviors/reactions'
import './github/behaviors/remote-form'
import './github/behaviors/removed-contents'
import './github/behaviors/render-editor'
import './github/behaviors/enrichable-markdown-render'
import './github/behaviors/replace-remote-form'
import './github/behaviors/report-long-tasks'
import './github/behaviors/responsive-underlinenav'
import './github/behaviors/saved-replies'
import './github/behaviors/scanning'
import './github/behaviors/session-resume'
import './github/behaviors/side-navigation'
import './github/behaviors/site-notifications'
import './github/behaviors/site-search'
import './github/behaviors/size-to-fit'
import './github/behaviors/smooth-scroll-anchor'
import './github/behaviors/snippet-clipboard-copy'
import './github/behaviors/social'
import './github/behaviors/socket-channel'
import './github/behaviors/sso-auto-replay'
import './github/behaviors/stale-session'
import './github/behaviors/statuses'
import './github/behaviors/sticky'
import './github/behaviors/sticky-is-stuck'
import './github/behaviors/sudo-required'
import './github/behaviors/suggesters/emoji-suggester'
import './github/behaviors/suggesters/issue-suggester'
import './github/behaviors/suggesters/mention-suggester'
import './github/behaviors/survey'
import './github/behaviors/sync-details-text'
import './github/behaviors/tab-container'
import './github/behaviors/tag-input'
import './github/behaviors/targetable-elements'
import './github/behaviors/task-list'
import './github/behaviors/team-members'
import './github/behaviors/timeline/marker'
import './github/behaviors/timeline/progressive'
import './github/behaviors/timeline/timeline-accessibility'
import './github/behaviors/toggler'
import './github/behaviors/register-service-worker'
import '@github-ui/turbo'
import './github/behaviors/tz-cookie'
import './github/behaviors/sudo-credential-options'
import './github/behaviors/unread-comments'
import './github/behaviors/unread-item-counter'
import './github/behaviors/updatable-content-observer'
import './github/behaviors/updatable-content-stale-object'
import './github/behaviors/upload-avatar'
import './github/behaviors/user-content'
import './github/behaviors/user-select-contain-polyfill'
import './github/behaviors/user-status-loader'
import './github/behaviors/user-lists'
import './github/behaviors/warn-unsaved-changes'
import './github/behaviors/will-transition-once'
import './github/issues/issue-link'
import './github/issues/labels'
import './github/issues/sidebar'
import './github/memex/search'
import {setup as setupSoftNav} from '@github-ui/soft-nav/setup'
import {setupWebVitals} from '@github-ui/web-vitals/setup'
import {setupCPUCookie} from '@github-ui/cpu-cookie'

setupSoftNav()
setupWebVitals()
setupCPUCookie()
